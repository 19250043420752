@for (alert of alerts; track $index) {
  <div class="alert mb-3" [ngClass]="alert.type">
    <div class="flex justify-center items-center h-full">
      @if (alert.type === 'success') {
        <i class="icon fa-light fa-check"></i>
      } @else if (alert.type === 'info') {
        <i class="icon fa-kit fa-info"></i>
      } @else {
        <i class="icon fa-light fa-triangle-exclamation"></i>
      }
    </div>
    <div class="w-full">
      @if (alert.title) {
        <div class="font-bold" [innerText]="alert.title | translate"></div>
      }
      <p class="body-medium" [innerHtml]="alert.message | translate"></p>
    </div>
    <div *ngIf="alert.dismissible" class="flex text-right justify-center items-center cursor-pointer">
      <div class="icon" (click)="closeAlert(alert)">
        <i class="icon fa-light fa-xmark"></i>
      </div>
    </div>
    <div *ngIf="alert.resolveButton?.link && alert.resolveButton?.text">
      <button *ngIf="alert.type === 'error'" class="button-danger-outlined h-full w-full my-2" [routerLink]="alert.resolveButton!.link">
        {{ alert.resolveButton!.text | translate }}
      </button>
    </div>
  </div>
}
