@if (!showOnlyDemoLogin) {
  <div class="py-8">
    @if (!emailSent) {
      <div class="card-responsive card-horizontal mx-auto">
        <div class="card-body">
          <div class="mb-5">
            <img [src]="'/assets/wipark/wipark_full.png' | url" class="my-4 px-4" alt="Arivo" />
          </div>
          <form [formGroup]="logInForm" (ngSubmit)="sendLogInEmail()">
            <div class="ar-input-group text-left mb-4">
              <div class="input-primary-container">
                <input id="email" type="email" class="input-primary" addEmptyPlaceholder formControlName="email" #emailInput />
                <label for="email" class="label-primary" [innerText]="'LogIn.EmailAddress' | translate">Email</label>
                <p class="error mt-1" *formError="'email.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
                <p class="error mt-1" *formError="'email.email'" [innerText]="'Common.Errors.ValidEmailIsRequired' | translate"></p>
                <p class="error mt-1" *formError="'email.notFound'" [innerText]="'Common.Errors.EmailNotFound' | translate"></p>
                <p class="error mt-1" *formError="'email.notVerified'" [innerText]="'Common.Errors.EmailNotVerified' | translate"></p>
                <p class="error mt-1" *formError="'email.emailBanned'" [innerText]="'Common.Errors.EmailBanned' | translate"></p>
                <p class="error mt-1" *formError="'email.tooManyRequests'" [innerText]="'Common.Errors.TooManyRequests' | translate"></p>
                <p class="error mt-1" *formError="'email.unknownError'" [innerText]="'Common.Errors.UnknownError' | translate"></p>
              </div>
            </div>
            <button class="button-primary w-full" type="submit" [innerText]="'LogIn.SendLoginLink' | translate"></button>
            <button
              *ngIf="showDemoLogin"
              type="button"
              class="button-primary-outlined w-full mt-2"
              (click)="logIn()"
              [innerText]="'Demo Login' | translate"
            ></button>
          </form>

          <div class="hidden">
            <div class="separator my-4" [innerText]="'Common.Or' | translate"></div>
            <button class="button-primary-outlined w-full gap-2 my-3">
              <i class="fa-brands fa-google"></i>
              <span [innerText]="'LogIn.SignInWith' | translate: { provider: 'Google' }"></span>
            </button>
            <button class="button-primary-outlined w-full gap-2 my-3">
              <i class="fa-brands fa-apple"></i>
              <span [innerText]="'LogIn.SignInWith' | translate: { provider: 'Apple' }"></span>
            </button>
          </div>
          <a
            routerLink="/onboarding"
            class="body-medium text-interaction-dark block text-center mt-4"
            [title]="'LogIn.DoNotHaveAnAccountYet' | translate"
            [innerText]="'LogIn.DoNotHaveAnAccountYet' | translate"
          ></a>
        </div>
        <div class="card-body text-left my-auto">
          <app-customer-service-info />
        </div>
      </div>
    } @else {
      <app-login-email [email]="email" (cancelEvent)="emailSent = false" />
    }
  </div>
} @else {
  <div class="py-8">
    <div class="card-responsive card-horizontal mx-auto">
      <div class="card-body">
        <div class="mb-5">
          <div class="headline-x-small text-decoration-dark text-center" [innerText]="'Common.LogIn' | translate"></div>
        </div>
        <div class="mb-10">
          <div class="body-large text-decoration-dark text-center" [innerText]="'Temporary.DemoEnvironment' | translate"></div>
        </div>
        <button type="button" class="button-primary-outlined w-full mt-2" (click)="logIn()" [innerText]="'Demo Login' | translate"></button>
      </div>
      <div class="card-body text-left my-auto">
        <app-customer-service-info />
      </div>
    </div>
  </div>
}
