<div class="headline-small mb-2 md:mb-4" [innerText]="'Help.HelpAndContact' | translate"></div>

<div class="card card-horizontal">
  <div class="card-body">
    <div>
      <div class="body-large text-decoration-dark mb-5" [innerText]="'Help.AccessCustomerSupport' | translate"></div>
      <div class="mb-4">
        <div class="body-medium text-decoration-dark mb-2" [innerText]="'Help.CustomerSupportWipark' | translate"></div>
        <div class="flex items-center gap-2 mb-2 text-interaction">
          <i class="fa-kit fa-phone"></i>
          <p class="body-medium">
            <a href="tel:+431600302248122" class="hover:text-interaction-light text-interaction no-underline">+43 1 600 30 22 48122</a>
          </p>
        </div>
        <div class="flex items-center gap-2 text-interaction">
          <i class="fa-kit fa-mail"></i>
          <p class="body-medium">
            <a href="mailto:info@wipark.at" class="hover:text-interaction-light text-interaction no-underline">info&#64;wipark.at</a>
          </p>
        </div>
      </div>
      <p class="body-medium" [innerHtml]="'Help.FurtherInformation' | translate: { url: 'https://www.wipark.at/unternehmen/kontakt' }"></p>
    </div>
  </div>
  <div class="card-body">
    <div class="body-large text-decoration-dark mb-5" [innerText]="'Help.UserManualHeader' | translate"></div>

    <div class="flex items-center gap-2 text-interaction body-large">
      <i class="fa-light fa-book-heart text-3xl"></i>
      <p>
        <a
          href="https://docs.parken.arivo.app/kunden/serviceportal_neu/"
          target="_blank"
          class="hover:text-interaction-light text-interaction no-underline"
          [title]="'Help.UserManual' | translate"
          [innerText]="'Help.UserManual' | translate"
        >
        </a>
      </p>
    </div>
  </div>
</div>
