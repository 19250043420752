import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

/**
 * The service that manages the storage of application data.
 *
 * The service uses `localStorage` by default.
 * If the Web Storage API is disabled or if the storage limit is reached, the in-memory storage is used.
 */
@Injectable({ providedIn: 'root' })
export class StorageManagerService {
  private items: Map<string, any> = new Map<string, any>();

  constructor() {}

  public store(key: string, value: any, storage: Storage = localStorage): boolean {
    const stringValue = JSON.stringify(value);
    this.items.set(key, stringValue);

    try {
      storage.setItem(key, stringValue);
    } catch (error) {}

    return true;
  }

  public load<T = any>(key: string, storage: Storage = localStorage): T | null {
    if (this.items.has(key)) {
      return JSON.parse(this.items.get(key));
    } else {
      try {
        const data: string | null = storage ? storage.getItem(key) : null;
        if (data) {
          this.items.set(key, data);
          return JSON.parse(data);
        } else {
          return null;
        }
      } catch (error) {
        Sentry.captureException(error);
        return null;
      }
    }
  }

  public delete(key: string, storage: Storage = localStorage): boolean {
    this.items.delete(key);

    try {
      storage.removeItem(key);
    } catch (error) {}

    return true;
  }
}
