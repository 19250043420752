@if (garage && product) {
  <form [formGroup]="contractForm" (ngSubmit)="finish()">
    <div class="card-responsive mb-4">
      <div class="card-header">
        <div class="headline-x-small">
          <i class="max-md:text-2xl fa-kit fa-contract"></i>
          <span [innerText]="'Contracts.ContractDetails' | translate"></span>
        </div>
      </div>
      <div class="card-body">
        <div class="well flex gap-2 w-[100%] mb-6">
          <div class="flex-none text-success-dark text-3xl">
            <i class="fa-kit fa-parking"></i>
          </div>
          <div class="flex-1 flex flex-col md:flex-row md:items-center justify-between gap-2 md:gap-4">
            <div class="flex-1 text-left">
              <div class="body-large mb-0.5" [innerText]="garage.name"></div>
              <div class="body-small">
                <span class="text-neutral-50">
                  <span [innerText]="product.name"></span>
                  @if (contractForm.value.parkingSpots) {
                    <span>,&nbsp;</span>
                    <span [innerText]="'Contracts.ParkingSpaces' | translatePlural: { count: contractForm.value.parkingSpots }"></span>
                  }
                </span>
              </div>
            </div>
            <div class="flex md:items-center justify-between flex-col md:flex-row gap-2 lg:gap-8">
              <div class="flex flex-row md:flex-col gap-1 md:gap-0.5 text-right">
                <div class="body-medium">
                  <span class="font-medium" [innerText]="product.price.amount | localizedCurrency: product.price.currency"></span>
                </div>
                <div class="body-medium" [innerText]="'Contracts.MonthlyPerParkingSpace' | translate"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="body-large text-decoration-dark text-left mb-2" [innerText]="'Contracts.ContractConditions' | translate"></div>
        <div class="flex flex-col md:flex-row gap-4 mb-4">
          <div class="ar-input-group text-start flex-1">
            <div class="input-primary-container relative">
              <input
                id="start"
                class="input-primary"
                type="text"
                addEmptyPlaceholder
                [matDatepickerFilter]="startDatepickerFilter"
                [matDatepicker]="startDatepicker"
                formControlName="start"
                (click)="startDatepicker.open()"
                readonly
              />
              <label for="start" class="label-primary" [innerText]="'Contracts.StartDate' | translate"></label>
              <mat-datepicker-toggle [for]="startDatepicker" class="absolute right-0 top-0"></mat-datepicker-toggle>
              <mat-datepicker #startDatepicker></mat-datepicker>
            </div>
            <p class="error" *formError="'start.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
          </div>
          <div class="ar-input-group text-start flex-1">
            <div class="input-primary-container">
              <input id="vehicleQuota" class="input-primary" type="number" min="1" addEmptyPlaceholder formControlName="parkingSpots" />
              <label for="vehicleQuota" [innerText]="'Contracts.NumberOfParkingSpaces' | translate"></label>
            </div>
            <p class="error" *formError="'parkingSpots.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
            <p class="error" *formError="'parkingSpots.min'" [innerText]="'Common.Errors.ValueMustBePositive' | translate"></p>
          </div>
        </div>
        <div class="flex text-left gap-4 flex-wrap">
          <div class="flex-grow">
            <div class="flex flex-1 gap-4">
              <div class="flex-1">
                <div class="body-medium" [innerText]="'Common.Months' | translatePlural: { count: product.termination_period }"></div>
                <p class="body-small">
                  <span class="text-neutral-50" [innerText]="'Contracts.TerminationPeriod' | translate"></span>
                </p>
              </div>
              <div class="flex-1 text-right">
                <div class="body-medium">
                  @if (loading) {
                    <mat-progress-spinner class="ml-auto" diameter="20" mode="indeterminate"></mat-progress-spinner>
                  } @else {
                    <span
                      class="text-interaction-dark font-medium"
                      [innerText]="totalPrice | localizedCurrency: product.price.currency"
                    ></span>
                  }
                </div>
                <p class="body-small">
                  <span class="text-interaction-dark" [innerText]="'Contracts.TotalPerMonthIncludingVAT' | translate"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
        @if (product.min_contract_period || !isFirstOfMonth) {
          <div class="flex mt-2 text-left gap-4 flex-wrap">
            <div class="flex-grow">
              <div class="flex flex-1 gap-4">
                <div class="flex-1" *ngIf="product.min_contract_period">
                  <div class="body-medium" [innerText]="'Common.Months' | translatePlural: { count: product.min_contract_period }"></div>
                  <p class="body-small">
                    <span class="text-neutral-50" [innerText]="'Contracts.MinimumContractPeriod' | translate"></span>
                  </p>
                </div>
                <div class="flex-1 text-right" *ngIf="!isFirstOfMonth">
                  <div class="body-medium">
                    @if (loading) {
                      <mat-progress-spinner class="ml-auto" diameter="20" mode="indeterminate"></mat-progress-spinner>
                    } @else {
                      <span
                        class="text-interaction-dark font-medium"
                        [innerText]="proratedPrice | localizedCurrency: product.price.currency"
                      ></span>
                    }
                  </div>
                  <p class="body-small">
                    <span class="text-interaction-dark" [innerText]="'Contracts.ProratedPriceIncludingVAT' | translate"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        }
        <hr class="my-4" />

        <div class="body-large text-decoration-dark text-left mb-2" [innerText]="'Contracts.EligibleVehicles' | translate"></div>
        <div class="ar-input-group text-left">
          <app-contract-vehicles
            [userLicensePlates]="licensePlates"
            [vehicleQuota]="contractForm.value.parkingSpots"
            (onLicensePlatesChanged)="updateLicensePlates($event)"
          />
        </div>

        <hr class="my-6" />

        <div class="ar-input-group text-left">
          <div class="flex items-center gap-2">
            <input id="switch" type="checkbox" class="switch" formControlName="termsAndConditions" />
            <label
              for="switch"
              class="body-medium"
              [innerHtml]="
                'Common.IHaveReadAndAcceptTheTermsAndConditions'
                  | translate: { url: 'https://www.wipark.at/o/document/nutzungsbedingungen_serviceportal' }
              "
            ></label>
          </div>
          <p
            class="error"
            *formError="'termsAndConditions.required'"
            [innerText]="'Common.Errors.YouHaveToAcceptTheTermsAndConditions' | translate"
          ></p>
        </div>
      </div>
    </div>

    <arivo-step-footer>
      <button
        class="button-primary"
        type="submit"
        [innerText]="'Contracts.ConcludeContractWithCosts' | translate"
        [disabled]="loading"
      ></button>
      <button
        class="button-primary-text"
        type="button"
        (click)="stepperComponent.previous()"
        [innerText]="'Common.Back' | translate"
      ></button>
    </arivo-step-footer>
  </form>
}
