import { Component, DestroyRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { GarageContract } from '../../../shared/models/contract';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { CoreData, DashboardResolve } from '../../../shared/models/routeTyping';
import { ArivoDialogComponent } from '../../../shared/components/ui-components/arivo-dialog/arivo-dialog.component';
import { HttpClient } from '@angular/common/http';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '../../../../environments/environment';
import { CoreDataService } from '../../../core/services/core-data.service';
import { formatAddress } from '../../../shared/utils/common.utils';

@Component({
  selector: 'app-dashboard-contracts',
  templateUrl: './dashboard-contracts.component.html',
  styleUrl: './dashboard-contracts.component.scss',
})
export class DashboardContractsComponent implements OnInit {
  @ViewChild('qrDialog') qrDialog!: ArivoDialogComponent;
  private _destroyRef = inject(DestroyRef);
  qrCode: null | string = null;
  hasPaymentMethod = false;
  creatingContractEnabled = environment.featureFlags.enableContractCreation;
  contractQrCodeEnabled = environment.featureFlags.enableContractQrCode;

  contracts: GarageContract[] = [];

  constructor(
    @Inject(ActivatedRoute) private route: TypedRoute<DashboardResolve>,
    private coreDataService: CoreDataService,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((data) => {
      this.contracts = data.dashboardApiResponse.garage_contracts;
    });
    this.coreDataService.coreData.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((coreData: CoreData) => {
      this.hasPaymentMethod = coreData.signup_status.has_payment_method;
    });
  }

  isRpcFallbackGarage(contract: GarageContract): boolean {
    if (!contract.garage) {
      return false;
    }
    return (
      !!contract.garage.name && !contract.garage.city && !contract.garage.street && !contract.garage.number && !contract.garage.zip_code
    );
  }

  openQrDialog(id: GarageContract['id']) {
    this.qrCode = null;
    this.qrDialog.open = true;
    this.http
      .get('https://meyer2.digimon.arivo.fun/api/appearance/qr/png/MGBDQ3VZU2c4SDNrVGllWHhDUUVxeWlncmdhTVhC/', { responseType: 'blob' })
      .subscribe((imageBlob: Blob) => {
        this.qrCode = URL.createObjectURL(imageBlob);
      });
  }

  dateIsInFuture(date: Date): boolean {
    return date > new Date();
  }

  protected readonly formatAddress = formatAddress;
}
