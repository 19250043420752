import { Component, DestroyRef, EventEmitter, Inject, inject, Input, OnInit, Output } from '@angular/core';
import { ArivoStepperComponent } from '../../../../shared/components/ui-components/arivo-stepper/arivo-stepper.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ArivoPaymentService } from '../../../../shared/components/arivo-payment-container/arivo-payment.service';
import { DialogService } from '../../../../shared/services/dialog.service';
import { ArivoPaymentMethod } from '../../../../shared/models/common';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../../../shared/utils/router.utils';
import { OnboardingFinishResolve } from '../../../../shared/models/routeTyping';
import { StorageManagerService } from '../../../../core/services/storage-manager.service';

@Component({
  selector: 'app-onboarding-finish-first-step',
  templateUrl: './onboarding-finish-first-step.component.html',
  styleUrl: './onboarding-finish-first-step.component.scss',
})
export class OnboardingFinishFirstStepComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  paymentMethod: ArivoPaymentMethod | null = null;
  creditCardIconClass: string | null = null;
  showErrorComponent: boolean = false;
  showNextButton: boolean = false;

  @Input({ required: true }) stepperComponent?: ArivoStepperComponent;
  @Output() cancelEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private paymentService: ArivoPaymentService,
    private dialogService: DialogService,
    private storeManager: StorageManagerService,
    @Inject(ActivatedRoute) private route: TypedRoute<OnboardingFinishResolve>,
  ) {}

  ngOnInit() {
    if (this.storeManager.load('userCreatedFromBackoffice') === 'true') this.dialogService.showWiparkWelcomeDialog();

    this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
      this.paymentMethod = data.onboardingFinishApiResolve.payment_method ?? null;
      if (this.paymentMethod) {
        this.showNextButton = true;
        this.paymentService.setActivePaymentMethod(this.paymentMethod);
      }
    });

    this.paymentService.activePaymentMethod.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((paymentMethod) => {
      this.paymentMethod = paymentMethod;
      if (this.paymentMethod) {
        this.creditCardIconClass = this.paymentService.getCreditCardIcon(this.paymentMethod.brand);
      }
    });
  }

  next(): void {
    if (this.stepperComponent && (!!this.paymentMethod || this.showErrorComponent)) {
      this.stepperComponent.selectedIndex = 1;
    }
    this.showNextButton = true;
  }
}
