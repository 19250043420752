<form [formGroup]="contractForm" (ngSubmit)="continue()">
  <div class="card-responsive mb-4">
    <div class="card-header">
      <div class="headline-x-small flex items-center gap-2">
        <i class="max-md:text-2xl fa-kit fa-parking-space"></i>
        <span [innerText]="'Contracts.SelectLocationAndContract' | translate"></span>
      </div>
    </div>
    <div class="card-body text-left">
      <div class="body-large text-decoration-dark mb-2" [innerText]="'Contracts.SelectLocation' | translate"></div>
      <div class="input-primary-container mb-6">
        <select class="input-primary" formControlName="garage">
          @for (garage of garages; track $index) {
            <option [value]="garage.id">
              {{ garage.name }} - {{ garage.street }} {{ garage.number }}, {{ garage.zip_code }} {{ garage.city }}
            </option>
          }
        </select>
        <label [innerText]="'Contracts.Location' | translate"></label>
      </div>

      @if (garage) {
        <div class="body-large text-decoration-dark mb-2" [innerText]="'Contracts.SelectContract' | translate"></div>
        @if (bookableContractTemplates.length > 0) {
          <div class="flex flex-col gap-2">
            @for (product of bookableContractTemplates; track product.id) {
              <label class="button-select button-select-small button-select-radio flex-1">
                <input id="product" type="radio" [value]="product.id" formControlName="product" />
                <span>
                  <div class="flex justify-between gap-2 md:gap-4 w-[100%]">
                    <div class="text-left flex items-center" [ngClass]="{ 'flex items-center': !product.description }">
                      <div class="body-medium text-decoration-dark mb-0.5">
                        <span class="font-medium" [innerText]="product.name"></span>
                      </div>
                      <div class="body-medium" *ngIf="product.description">
                        <span class="text-neutral-50" [innerText]="product.description"></span>
                      </div>
                    </div>
                    <div class="flex-grow flex justify-end gap-2 lg:gap-8">
                      <div class="text-right">
                        <div class="body-medium text-interaction mb-0.5">
                          <span class="font-medium" [innerText]="product.price.amount | localizedCurrency: product.price.currency"></span>
                        </div>
                        <div class="body-medium" [innerText]="'Common.Monthly' | translate"></div>
                      </div>
                    </div>
                  </div>
                </span>
              </label>
            }
          </div>
        } @else {
          <div class="body-medium">
            <span class="text-neutral-50" [innerText]="'Contracts.NoContractsAvailable' | translate"></span>
          </div>
        }

        @if (informationContractTemplates.length > 0) {
          <div class="body-large mt-5">
            <span class="text-decoration-dark" [innerText]="'Contracts.NotBookableOnline' | translate"></span>
          </div>
          <div class="body-medium mt-0.5 mb-2" [innerText]="'Contracts.ContactUsToBookAdditionalContracts' | translate"></div>
          <div class="flex flex-col gap-2">
            @for (product of informationContractTemplates; track product.id) {
              <div class="card-contract-information">
                <div class="flex justify-between gap-2 md:gap-4 w-[100%]">
                  <div class="text-left flex items-center" [ngClass]="{ 'flex items-center': !product.description }">
                    <div class="body-medium text-decoration-dark mb-0.5">
                      <span class="font-medium" [innerText]="product.name"></span>
                    </div>
                    <div class="body-medium" *ngIf="product.description">
                      <span class="text-neutral-50" [innerText]="product.description"></span>
                    </div>
                  </div>
                  <div class="flex-grow flex justify-end gap-2 lg:gap-8">
                    <div class="text-right">
                      <div class="body-medium text-interaction mb-0.5">
                        <span class="font-medium" [innerText]="product.price.amount | localizedCurrency: product.price.currency"></span>
                      </div>
                      <div class="body-medium" [innerText]="'Common.Monthly' | translate"></div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        }
      }
    </div>
  </div>

  <arivo-step-footer>
    <button class="button-primary" type="submit" [innerText]="'Common.Continue' | translate"></button>
    <button class="button-primary-text" type="button" (click)="back()" [innerText]="'Common.Back' | translate"></button>
  </arivo-step-footer>
</form>
