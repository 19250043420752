import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ToastService } from '../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { BanStatus, CoreData } from '../../shared/models/routeTyping';

export type WarningData = {
  paymentMissing: boolean;
  customerAddressMissing: boolean;
  banStatus?: BanStatus;
};

@Injectable({
  providedIn: 'root',
})
export class CoreDataService {
  defaultCoreData: CoreData = {
    signup_status: {
      has_payment_method: false,
      has_customer_address: false,
      has_enabled_pay_per_use_license_plates: false,
    },
  };

  coreData = new BehaviorSubject<CoreData>(this.defaultCoreData);
  warningData = new Subject<WarningData>();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {
    this.coreData.subscribe({
      next: (coreData) => {
        this.warningData.next({
          paymentMissing: !coreData.signup_status.has_payment_method,
          customerAddressMissing: !coreData.signup_status.has_customer_address,
          banStatus: coreData.ban_status,
        });
      },
    });
  }

  fetchCoreData(): Observable<CoreData> {
    return this.http.get<CoreData>(`/api/backoffice/v2/customer_portal/core/`).pipe(
      catchError((error) => {
        if (error.status === 401) {
          this.toastService.add({ type: 'error', message: this.translateService.instant('Common.Errors.TokenInvalid') });
          this.authService.logOut();
          return of();
        } else {
          return of(this.defaultCoreData);
        }
      }),
    );
  }

  setCoreData(coreData: CoreData): void {
    this.coreData.next(coreData);
  }

  setPaymentMethodAvailability(hasPaymentMethod: boolean, hideBanWarning: boolean = false): void {
    const currentData = this.coreData.value;
    if (hasPaymentMethod && hideBanWarning) {
      delete currentData.ban_status;
    }
    const updatedData = {
      ...currentData,
      signup_status: {
        ...currentData.signup_status,
        has_payment_method: hasPaymentMethod,
      },
    };
    this.coreData.next(updatedData);
  }

  setCustomerAddressCompletion(hasCustomerAddress: boolean): void {
    const currentData = this.coreData.value;
    const updatedData = {
      ...currentData,
      signup_status: {
        ...currentData.signup_status,
        has_customer_address: hasCustomerAddress,
      },
    };
    this.coreData.next(updatedData);
  }

  setEnabledPayPerUseLicensePlateStatus(hasEnabledPayPerUseLicensePlate: boolean): void {
    const currentData = this.coreData.value;
    const updatedData = {
      ...currentData,
      signup_status: {
        ...currentData.signup_status,
        has_enabled_pay_per_use_license_plates: hasEnabledPayPerUseLicensePlate,
      },
    };
    this.coreData.next(updatedData);
  }
}
