<div class="headline-small text-center mb-4" [innerText]="'Contracts.NewContract' | translate"></div>
@if (bookableContractsAvailable) {
  <arivo-stepper #stepperComponent>
    <arivo-step [label]="'Contracts.LocationContract' | translate">
      <app-contract-create-first-step [stepperComponent]="stepperComponent" [garagesObservable]="garagesObservable" />
    </arivo-step>
    <arivo-step [label]="'Contracts.ContractDetails' | translate">
      <app-contract-create-second-step [stepperComponent]="stepperComponent" />
    </arivo-step>
  </arivo-stepper>
} @else {
  <div class="text-center my-6">
    <div class="text-neutral-50 text-2xl">
      <i class="fa-kit fa-contract"></i>
    </div>
    <div class="body-medium mt-2">
      <span class="text-neutral-50" [innerText]="'Contracts.NoContractsAvailable' | translate"></span>
    </div>
    <div class="flex justify-center mt-2">
      <button class="button-primary-text" type="button" (click)="back()" [innerText]="'Common.Back' | translate"></button>
    </div>
  </div>
}
