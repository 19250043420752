import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { delay, Observable, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  addRandomProductsToGarages,
  exampleBillingInvoices,
  exampleGarageContracts,
  exampleGarages,
  exampleLicensePlates,
  exampleOpenCosts,
  examplePaymentMethod,
  examplePayPerUseLicensePlates,
  exampleProducts,
  exampleSignupSearchResponse,
  exampleUser,
  exampleVehicles,
  generateExampleUnpaidParkingSessions,
  generateRandomOpenParkingSessions,
} from '../utils/example.data';
import { LicensePlate, PayPerUseLicensePlate, Vehicle } from '../../shared/models/licensePlate';
import { GarageContractCreate } from '../../shared/models/contract';
import { StorageManagerService } from '../services/storage-manager.service';
import { CoreData } from '../../shared/models/routeTyping';
import { ContractTemplate } from '../../shared/models/contractTemplate';

type MockApiEndpoint = {
  url_start: string;
  url_end?: string;
  method: string;
  response: (req?: HttpRequest<any>) => Observable<HttpResponse<any>>;
};

@Injectable()
export class MockHttpInterceptor implements HttpInterceptor {
  constructor(
    private http: HttpClient,
    private storageManagerService: StorageManagerService,
  ) {}

  //prettier-ignore
  private mockApiEndpoints: MockApiEndpoint[] = [
    //AUTH
    { url_start: '/api/user/v2/auth/login/email/',      method: 'POST', response: this.mockLoginEmailResponse.bind(this) },
    { url_start: '/api/user/v2/auth/login/verify/',     method: 'POST', response: this.mockLoginVerify.bind(this) },
    { url_start: '/api/user/v2/auth/register/verify/',  method: 'POST', response: this.mockRegisterVerifyResponse.bind(this) },
    { url_start: '/api/user/v2/auth/register/email',    method: 'POST', response: this.mockRegisterEmailResponse.bind(this) },
    //RESOLVERS
    { url_start: '/api/backoffice/v2/customer/license_plate/',                        method: 'GET', response: this.mockVehiclesResponse.bind(this) },
    { url_start: '/api/backoffice/v2/customer_portal/dashboard_data/',                method: 'GET', response: this.mockDashboardResponse.bind(this),},
    { url_start: '/api/invoice/v2/customer_portal/billing_data/',                     method: 'GET', response: this.mockBillingResponse.bind(this) },
    { url_start: '/api/backoffice/v2/customer_portal/contract/', url_end: '/create/', method: 'GET', response: this.mockContractCreateResponse.bind(this) },
    { url_start: '/api/backoffice/v2/customer_portal/contract/', url_end: '/update/', method: 'GET', response: this.mockContractEditResponse.bind(this) },
    { url_start: '/api/backoffice/v2/customer_portal/core/',                          method: 'GET', response: this.mockCoreDataResponse.bind(this) },
    { url_start: '/api/backoffice/v2/customer_portal/onboarding/',                    method: 'GET', response: this.mockOnboardingResponse.bind(this) },
    { url_start: '/api/backoffice/v2/customer_portal/products/',                      method: 'GET', response: this.mockProductsResponse.bind(this) },
    { url_start: '/api/backoffice/v2/customer/self/',                                 method: 'GET', response: this.mockUserResponse.bind(this) },
    //ACTIONS
    { url_start: '/api/backoffice/v2/customer_portal/door_opener_qr_code/',                     method: 'GET', response: this.mockQrCodeResponse.bind(this) },
    { url_start: '/api/backoffice/v2/customer/license_plate/',                                  method: 'DELETE', response: this.mockVehicleDeleteResponse.bind(this) },
    { url_start: '/api/backoffice/v2/customer_portal/pay_per_use/',                             method: 'POST', response: this.mockAddPayPerUseResponse.bind(this) },
    { url_start: '/api/backoffice/v2/customer_portal/pay_per_use/get_or_create/',               method: 'POST', response: this.mockTogglePayPerUseResponse.bind(this) },
    { url_start: '/api/backoffice/v2/customer_portal/pay_per_use/delete_if_exists/',            method: 'PUT', response: this.mockTogglePayPerUseResponse.bind(this) },
    { url_start: '/api/backoffice/v2/customer_portal/contract/',  url_end: '/contract/',        method: 'POST', response: this.mockContractPostResponse.bind(this) },
    { url_start: '/api/backoffice/v2/customer_portal/contract/',  url_end: '/license_plates/',  method: 'PUT', response: this.mockContractPutResponse.bind(this) },
    { url_start: '/api/backoffice/v2/garage_contract/preview/',                                 method: 'GET', response: this.mockContractGetInfoResponse.bind(this) },
    { url_start: '/api/backoffice/v2/garage_contract/',           url_end: '/terminate/',       method: 'PUT', response: this.mockContractTerminateResponse.bind(this) },
    { url_start: '/api/onegate/v2/oss/signup_search',                                           method: 'GET', response: this.mockSignupSearchResponse.bind(this) },
    { url_start: '/api/backoffice/v2/customer/update/',                                         method: 'PUT', response: this.mockUserPutResponse.bind(this) },
    //ADYEN
    { url_start: '/api/invoice/v2/payment_method/', url_end: '/submit_authentication_details/', method: 'POST', response: this.mockAdditionalDetailsResponse.bind(this) },
    { url_start: '/api/invoice/v2/payment_method/', url_end: '/available_payment_methods/',     method: 'GET', response: this.mockAvailablePaymentMethodsResponse.bind(this) },
    { url_start: '/api/invoice/v2/payment_method/', url_end: '/payment_method/',                method: 'POST', response: this.mockInitiatePaymentSessionResponse.bind(this) },
  ];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      (environment.developmentFlags.enableMockCalls ||
        (this.storageManagerService.load('token') === 'demotoken' && environment.developmentFlags.enableDemoLogin)) &&
      req.url.startsWith('/api')
    ) {
      console.info('Mocking API call:', req.method, req.url);
      const endpoint = this.mockApiEndpoints.find((ep) => {
        const startsWith = req.url.startsWith(ep.url_start) && req.method === ep.method;
        const endsWith = ep.url_end ? req.url.endsWith(ep.url_end) : true;
        return startsWith && endsWith;
      });
      if (endpoint) {
        return endpoint.response(req);
      }
    }
    return next.handle(req);
  }

  mockAvailablePaymentMethodsResponse() {
    return this.handleRealResponse(
      'get',
      environment.name === 'development'
        ? environment.developmentFlags.devAdyenBackendUrl + '/available_payment_methods'
        : `https://wipark.digimon.arivo.fun/api/invoice/v2/payment_method/available_payment_methods/`,
    );
  }

  mockInitiatePaymentSessionResponse(req?: HttpRequest<any>) {
    return this.handleRealResponse(
      'post',
      environment.name === 'development'
        ? environment.developmentFlags.devAdyenBackendUrl + '/payment_method'
        : `https://wipark.digimon.arivo.fun/api/invoice/v2/payment_method/`,
      req!.body,
    );
  }

  mockAdditionalDetailsResponse(req?: HttpRequest<any>) {
    return this.handleRealResponse(
      'post',
      environment.name === 'development'
        ? environment.developmentFlags.devAdyenBackendUrl + '/submit_authentication_details'
        : `https://wipark.digimon.arivo.fun/api/invoice/v2/payment_method/submit_authentication_details/`,
      req!.body,
    );
  }

  mockValidateToken() {
    return this.storageManagerService.load('token') === 'invalidtoken' ? this.handleMockError(400) : this.handleMockResponse({}, 250);
  }

  mockRegisterVerifyResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development') {
      return this.handleMockResponse(
        {
          key: 'demotoken',
          transfer_error: false,
          parking_session: {
            amount_unpaid: {
              amount: '3.20',
              currency: 'EUR',
            },
            entry_time: '2024-08-01T11:29:15',
            duration_in_seconds: 2958,
            license_plate: {
              license_plate: 'G:WIPA100',
              license_plate_country: 'D',
            },
            is_cleared: false,
          },
        },
        250,
      );
    }
    return this.handleRealResponse('post', environment.developmentFlags.backendUrl + req!.url, req!.body);
  }

  mockLoginVerify(req?: HttpRequest<any>) {
    return environment.name === 'development'
      ? this.handleMockResponse({ key: 'demotoken' }, 250)
      : this.handleRealResponse('post', environment.developmentFlags.backendUrl + req!.url, req!.body);
  }

  mockQrCodeResponse() {
    return this.handleRealResponse(
      'get',
      `https://wipark.digimon.arivo.fun/api/backoffice/v2/customer_portal/door_opener_qr_code/`,
      undefined,
      { responseType: 'blob' },
    );
  }

  mockBillingResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development' || this.storageManagerService.load('token') === 'demotoken') {
      return this.handleMockResponse({
        payment_method: examplePaymentMethod,
        open_costs: exampleOpenCosts,
        unpaid_parking_sessions: generateExampleUnpaidParkingSessions(100),
        invoices: exampleBillingInvoices,
      });
    } else {
      return this.handleRealResponse('get', environment.developmentFlags.backendUrl + req!.url);
    }
  }

  mockContractCreateResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development' || this.storageManagerService.load('token') === 'demotoken') {
      let garages = exampleGarages;
      addRandomProductsToGarages(garages);
      return this.handleMockResponse({
        garages,
        license_plates: exampleLicensePlates,
      });
    } else {
      return this.handleRealResponse('get', environment.developmentFlags.backendUrl + req!.url);
    }
  }

  private mockContractEditResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development' || this.storageManagerService.load('token') === 'demotoken') {
      const contract = exampleGarageContracts.find((contract) => contract.id === req!.url.split('/')[6]);
      if (!contract) {
        console.warn(`Contract ${req!.url.split('/')[6]} not found!`);
        return this.handleMockError(404);
      }
      return this.handleMockResponse({ garage_contract: contract, license_plates: exampleLicensePlates });
    } else {
      return this.handleRealResponse('get', environment.developmentFlags.backendUrl + req!.url);
    }
  }

  mockDashboardResponse() {
    if (environment.name === 'development' || this.storageManagerService.load('token') === 'demotoken') {
      return this.handleMockResponse({
        payment_method: examplePaymentMethod,
        open_costs: exampleOpenCosts,
        garage_contracts: exampleGarageContracts,
        open_parking_sessions: [...generateRandomOpenParkingSessions(Math.floor(Math.random() * 5))],
        pay_per_use_license_plates: [...examplePayPerUseLicensePlates],
        pay_per_use_garage_count: 5,
      });
    } else {
      return this.handleRealResponse('get', `https://api.digimon.arivo.fun/backoffice/v2/customer_portal/dashboard_data/`);
    }
  }

  mockCoreDataResponse(req?: HttpRequest<any>) {
    if (this.storageManagerService.load('token') === 'demotoken' || environment.name === 'development') {
      return this.handleMockResponse<CoreData>({
        signup_status: {
          has_payment_method: true,
          has_customer_address: true,
          has_enabled_pay_per_use_license_plates: false,
        },
        // ban_status: { active: false, reason: 'other' },
      });
    } else {
      return this.handleRealResponse('get', environment.developmentFlags.backendUrl + req!.url);
    }
  }

  mockOnboardingResponse(req?: HttpRequest<any>) {
    if (this.storageManagerService.load('token') === 'demotoken' || environment.name === 'development') {
      return this.handleMockResponse({ customer: exampleUser, payment_method: null });
    } else {
      return this.handleRealResponse('get', environment.developmentFlags.backendUrl + req!.url);
    }
  }

  mockUserResponse(req?: HttpRequest<any>) {
    if (this.storageManagerService.load('token') === 'demotoken' || environment.name === 'development') {
      return this.handleMockResponse(exampleUser);
    } else {
      return this.handleRealResponse('get', environment.developmentFlags.backendUrl + req!.url);
    }
  }

  mockSignupSearchResponse(req?: HttpRequest<any>): Observable<HttpResponse<any>> {
    // Extract license plate details from the request URL
    const urlParams = new URLSearchParams(req!.url.split('?')[1]);
    const licensePlate = urlParams.get('license_plate');

    if (environment.name === 'development') {
      let mockSignupSearchResponse = exampleSignupSearchResponse;

      if (licensePlate?.startsWith('X')) {
        mockSignupSearchResponse = null;
      } else {
        mockSignupSearchResponse = {
          ...mockSignupSearchResponse,
          license_plate: {
            license_plate: licensePlate,
            license_plate_country: urlParams.get('license_plate_country'),
          },
          is_cleared: licensePlate?.startsWith('A'),
        };
      }
      return this.handleMockResponse(mockSignupSearchResponse);
    } else {
      return this.handleRealResponse('get', environment.developmentFlags.backendUrl + req!.url);
    }
  }

  mockTogglePayPerUseResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development' || this.storageManagerService.load('token') === 'demotoken') {
      const { license_plate, license_plate_country } = req!.body;

      const licensePlateIndex = examplePayPerUseLicensePlates.findIndex(
        (lp) => lp.license_plate === license_plate && lp.license_plate_country === license_plate_country,
      );

      if (licensePlateIndex !== -1) {
        if (req!.url.includes('get_or_create')) {
          examplePayPerUseLicensePlates[licensePlateIndex].enabled = true;
        } else {
          examplePayPerUseLicensePlates[licensePlateIndex].enabled = false;
        }
        return this.handleMockResponse(examplePayPerUseLicensePlates[licensePlateIndex]);
      } else {
        return this.mockAddPayPerUseResponse(req);
      }
    } else {
      return this.handleRealResponse(req!.method === 'PUT' ? 'put' : 'post', environment.developmentFlags.backendUrl + req!.url, req!.body);
    }
  }

  mockAddPayPerUseResponse(req?: HttpRequest<any>): Observable<any> {
    if (environment.name === 'development' || this.storageManagerService.load('token') === 'demotoken') {
      let licensePlate: PayPerUseLicensePlate = {
        ...req!.body,
        pay_per_use_enabled: true,
      };
      examplePayPerUseLicensePlates.push(licensePlate);
      exampleLicensePlates.push(req!.body);

      return this.handleMockResponse(licensePlate);
    } else {
      return this.handleRealResponse('post', environment.developmentFlags.backendUrl + req!.url, req!.body);
    }
  }

  mockContractPostResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development' || this.storageManagerService.load('token') === 'demotoken') {
      let request: GarageContractCreate = req!.body;

      const [garageId, productId] = request.contract_template_id.split('-');

      const existingGarage = exampleGarages.find((garage) => garage.id === garageId);
      const existingProduct = exampleProducts.find((product) => product.id === productId);

      let newContract: any = {
        ...request,
        id: Math.random().toString(36).substring(7),
        name: existingProduct.name,
        start: request.start,
        end: request.end ? request.end : null,
        garage: {
          name: existingGarage.name,
          street: existingGarage.street,
          number: existingGarage.number,
          zip_code: existingGarage.zip_code,
          city: existingGarage.city,
        },
        price: {
          amount: existingProduct.price.amount * request.group_limit,
          currency: 'EUR',
        },
        minimum_duration_months: existingProduct.minimum_duration_months,
        notice_period_months: existingProduct.notice_period_months,
      };
      exampleGarageContracts.push(newContract);
      return this.handleMockResponse(newContract);
    } else {
      return this.handleRealResponse('post', environment.developmentFlags.backendUrl + req!.url, req!.body);
    }
  }

  mockContractPutResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development' || this.storageManagerService.load('token') === 'demotoken') {
      const updatedContract = req!.body;
      const contractIndex = exampleGarageContracts.findIndex((contract) => contract.id === req!.url.split('/')[6]);
      if (contractIndex === -1) {
        return of(new HttpResponse({ status: 404, body: 'Contract not found' }));
      }

      if (updatedContract.license_plates) {
        updatedContract.license_plates.forEach((updatedLicensePlate: any) => {
          const existingLicensePlate = examplePayPerUseLicensePlates.find(
            (lp) =>
              lp.license_plate === updatedLicensePlate.license_plate &&
              lp.license_plate_country === updatedLicensePlate.license_plate_country,
          );

          if (!existingLicensePlate) {
            examplePayPerUseLicensePlates.push({
              ...updatedLicensePlate,
              pay_per_use_enabled: false,
            });
            exampleLicensePlates.push({
              ...updatedLicensePlate,
            });
          }
        });
      }

      exampleGarageContracts[contractIndex] = {
        ...exampleGarageContracts[contractIndex],
        ...updatedContract,
      };

      return this.handleMockResponse(exampleGarageContracts[contractIndex]);
    } else {
      return this.handleRealResponse('put', environment.developmentFlags.backendUrl + req!.url, req!.body);
    }
  }
  mockContractGetInfoResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development' || this.storageManagerService.load('token') === 'demotoken') {
      const url = req!.urlWithParams;
      const params = new URLSearchParams(url.split('?')[1]);
      const contract_template_id = params.get('contract_template_id')!;
      const group_limit = params.get('group_limit')!;
      const start = params.get('start')!;

      // Find the corresponding contract template from example data
      const contractTemplate: ContractTemplate = exampleProducts.find((product) => contract_template_id.endsWith(product.id));
      if (!contractTemplate) {
        return this.handleMockError(400);
      }

      let startDate = new Date(start);
      let lastDayOfMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
      let daysInMonth = lastDayOfMonth.getDate();
      let daysLeftInMonth = daysInMonth - startDate.getDate() + 1;

      // Assuming piece_price is the monthly price
      let piecePrice = contractTemplate.price.amount;
      let price = piecePrice * parseInt(group_limit);
      let firstPaymentPeriodPrice = (price / daysInMonth) * daysLeftInMonth;

      return this.handleMockResponse({
        name: contractTemplate.name,
        group_limit: 2,
        start: start,
        end: null,
        discount_type: 'P',
        discount_amount: '0.00',
        piece_price: piecePrice.toString(),
        price: price.toString(),
        price_currency: contractTemplate.price.currency,
        vat_percent: '20.00',
        first_termination_date: '2024-12-31',
        first_payment_period: {
          price: firstPaymentPeriodPrice,
          start: '2024-11-12',
          end: '2024-11-30',
        },
      });
    } else {
      return this.handleRealResponse('get', environment.developmentFlags.backendUrl + req!.url, req!.body);
    }
  }

  mockContractTerminateResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development' || this.storageManagerService.load('token') === 'demotoken') {
      const updatedContract = req!.body;
      const contractIndex = exampleGarageContracts.findIndex((contract) => contract.id === req!.url.split('/')[5]);
      if (contractIndex === -1) {
        return of(new HttpResponse({ status: 404, body: 'Contract not found' }));
      } else {
        exampleGarageContracts[contractIndex].end = updatedContract.end;
        return this.handleMockResponse({});
      }
    } else {
      return this.handleRealResponse('put', environment.developmentFlags.backendUrl + req!.url, req!.body);
    }
  }

  mockUserPutResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development' || this.storageManagerService.load('token') === 'demotoken') {
      exampleUser.email = req!.body.email;
      exampleUser.first_name = req!.body.first_name;
      exampleUser.last_name = req!.body.last_name;
      exampleUser.street = req!.body.street;
      exampleUser.street_number = req!.body.street_number;
      exampleUser.zip_code = req!.body.zip_code;
      exampleUser.city = req!.body.city;
      exampleUser.country = req!.body.country;
      exampleUser.business = req!.body.business;
      exampleUser.company_name = req!.body.company_name;
      exampleUser.vat_id = req!.body.vat_id;
      return this.handleMockResponse(null);
    } else {
      return this.handleRealResponse('put', environment.developmentFlags.backendUrl + req!.url, req!.body);
    }
  }

  mockProductsResponse(req?: HttpRequest<any>) {
    if (this.storageManagerService.load('token') === 'demotoken') {
      let garages = exampleGarages;
      addRandomProductsToGarages(garages);
      return this.handleMockResponse({
        map_options: {
          center: null,
          zoom: 14,
        },
        garages,
      });
    } else {
      return this.handleRealResponse('get', environment.developmentFlags.backendUrl + req!.url);
    }
  }

  mockVehiclesResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development' || this.storageManagerService.load('token') === 'demotoken') {
      const vehicles = examplePayPerUseLicensePlates.map((plate: PayPerUseLicensePlate) => {
        const vehicle: Vehicle = {
          id: Math.random().toString(36).substring(2, 15),
          license_plate_country: plate.license_plate_country,
          license_plate: plate.license_plate,
          pay_per_use_enabled: plate.pay_per_use_enabled,
          garage_contracts: [],
        };

        exampleGarageContracts.forEach((contract) => {
          if (
            contract.license_plates.some(
              (lp: LicensePlate) => lp.license_plate === plate.license_plate && lp.license_plate_country === plate.license_plate_country,
            )
          ) {
            vehicle.garage_contracts.push({
              id: contract.id,
              name: contract.name,
              group_limit: contract.group_limit,
              garage: contract.garage
                ? {
                    name: contract.garage.name,
                  }
                : null,
            });
          }
        });
        return vehicle;
      });
      exampleVehicles.length = 0;
      exampleVehicles.push(...vehicles);

      return this.handleMockResponse(vehicles);
    } else {
      return this.handleRealResponse('get', environment.developmentFlags.backendUrl + req!.url);
    }
  }

  mockVehicleDeleteResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development' || this.storageManagerService.load('token') === 'demotoken') {
      const vehicleId = req!.url.split('/')[6];
      const vehicle = exampleVehicles.find((vehicle) => vehicle.id === vehicleId);
      if (vehicle) {
        const { license_plate, license_plate_country } = vehicle;

        // Remove from examplePayPerUseLicensePlates
        const ppuIndex = examplePayPerUseLicensePlates.findIndex(
          (plate) => plate.license_plate === license_plate && plate.license_plate_country === license_plate_country,
        );
        if (ppuIndex !== -1) {
          examplePayPerUseLicensePlates.splice(ppuIndex, 1);
        }
        const plateIndex = exampleLicensePlates.findIndex(
          (plate) => plate.license_plate === license_plate && plate.license_plate_country === license_plate_country,
        );
        if (plateIndex !== -1) {
          exampleLicensePlates.splice(plateIndex, 1);
        }

        // Remove from exampleVehicles
        const vehicleIndex = exampleVehicles.indexOf(vehicle);
        exampleVehicles.splice(vehicleIndex, 1);
      }

      return this.handleMockResponse(null);
    } else {
      return this.handleRealResponse('delete', environment.developmentFlags.backendUrl + req!.url);
    }
  }

  mockRegisterEmailResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development') {
      return this.handleMockResponse({});
    } else {
      return this.handleRealResponse('post', environment.developmentFlags.backendUrl + req!.url, req!.body);
    }
  }

  mockLoginEmailResponse(req?: HttpRequest<any>) {
    return this.mockRegisterEmailResponse(req);
  }

  private handleMockResponse<T>(responseBody: T, delayMs: number = 500): Observable<HttpResponse<T>> {
    return of(new HttpResponse({ status: 200, body: responseBody })).pipe(delay(delayMs));
  }

  private handleMockError<T>(status: number, body?: T) {
    let request$: Observable<any> = this.http.get<T>('https://httpstat.us/' + status);

    return request$.pipe(switchMap((response) => of(new HttpResponse({ status: response.status, body: body ?? response.body }))));
  }

  private handleRealResponse<T>(method: string, url: string, body?: any, options?: any): Observable<HttpResponse<T>> {
    console.info('Mock overwritten by real API call:', url);
    let request$: Observable<any>;

    switch (method.toLowerCase()) {
      case 'get':
        request$ = this.http.get<T>(url, { observe: 'response', ...options });
        break;
      case 'post':
        request$ = this.http.post<T>(url, body, { observe: 'response', ...options });
        break;
      case 'put':
        request$ = this.http.put<T>(url, body, { observe: 'response', ...options });
        break;
      case 'delete':
        request$ = this.http.delete<T>(url, { observe: 'response', ...options });
        break;
      default:
        throw new Error(`Unsupported HTTP method: ${method}`);
    }

    return request$.pipe(switchMap((response) => of(new HttpResponse({ status: 200, body: response.body }))));
  }
}
