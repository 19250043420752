export const environment = {
  name: 'production',
  titleName: 'WIPARK Serviceportal',
  production: true,
  featureFlags: {
    enableMarketingConsent: true,
    enableContractCreation: false,
    enableContractQrCode: false,
    enableAccessCard: true,
    enableContractTermination: false,
    enableAppAndNotifications: false,
    enableCookieConsent: false,
  },
  developmentFlags: {
    enableDemoLogin: false,
    enableDemoLoginOnly: false,
    enableMockCalls: false,
    backendUrl: 'https://wipark.arivo.app',
    devAdyenBackendUrl: 'http://localhost:3000',
    requireCardHolderName: true,
  },
};
