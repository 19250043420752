import { Component, DestroyRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { BillingParkingSession } from '../../../shared/models/parkingSession';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { BillingResolve } from '../../../shared/models/routeTyping';
import { breakPoints } from '../../../core/utils/common.data';
import { sortArray } from '../../../shared/utils/common.utils';
import { ArivoPaginatorComponent } from '../../../shared/components/arivo-paginator/arivo-paginator.component';

@Component({
  selector: 'app-billing-park-sessions',
  templateUrl: './billing-parking-sessions.component.html',
  styleUrl: './billing-parking-sessions.component.scss',
})
export class BillingParkingSessionsComponent implements OnInit {
  _destroyRef = inject(DestroyRef);
  parkingSessions: BillingParkingSession[] = [];
  startOpen: boolean = false;

  orderedBy: 'name' | 'entry' | 'exit' = 'exit';
  orderedByOrder: 'asc' | 'desc' = 'desc';
  @ViewChild('paginator') paginator: ArivoPaginatorComponent | undefined;

  constructor(@Inject(ActivatedRoute) private route: TypedRoute<BillingResolve>) {}

  ngOnInit() {
    this.startOpen = window.innerWidth > breakPoints.md;
    this.route.data.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((data) => {
      this.parkingSessions = sortArray([...data.billingApiResponse.unpaid_parking_sessions], (x) => x.exit_date_time, 'desc');
    });
  }

  get paginatedSessions(): BillingParkingSession[] {
    if (this.paginator) {
      return this.parkingSessions.slice(this.paginator.startIndex, this.paginator.endIndex);
    } else {
      return this.parkingSessions.slice(0, 10);
    }
  }

  orderByName() {
    this.orderedByOrder = this.orderedBy === 'name' && this.orderedByOrder === 'asc' ? 'desc' : 'asc';
    this.orderedBy = 'name';

    sortArray(this.parkingSessions, (x) => x.garage_name, this.orderedByOrder);
  }

  orderByEntry() {
    this.orderedByOrder = this.orderedBy === 'entry' && this.orderedByOrder === 'asc' ? 'desc' : 'asc';
    this.orderedBy = 'entry';

    sortArray(this.parkingSessions, (x) => x.entry_date_time, this.orderedByOrder);
  }

  orderByExit() {
    this.orderedByOrder = this.orderedBy === 'exit' && this.orderedByOrder === 'asc' ? 'desc' : 'asc';
    this.orderedBy = 'exit';

    sortArray(this.parkingSessions, (x) => x.exit_date_time, this.orderedByOrder);
  }
}
