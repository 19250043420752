<div class="flex flex-col justify-center h-full">
  <div class="text-left">
    <a
      class="cursor-pointer inline-flex text-decoration-none items-center body-medium text-interaction-dark mb-4"
      (click)="cancelEvent.emit()"
    >
      <i class="fa-light fa-chevron-left"></i>
      <span class="text-underlined pl-2" [innerText]="'Common.Back' | translate"></span>
    </a>
  </div>
  <div class="flex-responsive items-center gap-4 mb-4">
    <i class="text-3xl text-decoration-dark fa-kit fa-email"></i>
    <div class="body-large text-left text-decoration-dark" [innerText]="'LogIn.PleaseCheckYourEmailInbox' | translate"></div>
  </div>
  <div class="well">
    <p class="body-medium mb-2" [innerHtml]="'LogIn.WeHaveSentAnEmail' | translate: { email: email }"></p>
    <p class="body-medium" [innerText]="'Onboarding.FollowTheLinkToSignUp' | translate"></p>
  </div>
  <div class="mt-4">
    <p class="body-medium">
      <span [innerText]="'LogIn.HaveNotReceivedAnEmail' | translate"></span>
      <span>&nbsp;</span>
      <a class="cursor-pointer" [innerText]="'LogIn.SendAgain' | translate" (click)="sendAgainEvent.emit()">Send Again</a>
    </p>
  </div>
</div>
