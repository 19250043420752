import { Component, DestroyRef, Inject, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserService } from './user.service';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../shared/utils/router.utils';
import { UserResolve } from '../../shared/models/routeTyping';
import { User } from '../../shared/models/user';
import { FormBuilder, Validators } from '@angular/forms';
import { fbBoolean, fbOptionalString, fbString } from '../../shared/utils/form.utils';
import { licensePlateCountries } from '../../shared/data/countries.data';
import { ISO2CountryCode } from '../../shared/models/country';
import { ToastService } from '../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../core/services/language.service';
import { environment } from '../../../environments/environment';
import { CoreDataService } from '../../core/services/core-data.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss',
})
export class UserComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  user: User | null = null;
  appAndNotificationsEnabled = environment.featureFlags.enableAppAndNotifications;

  //This only reorders the list on reload, if we need to have it instantly we may have to adjust this
  selectableCountries = licensePlateCountries
    .slice()
    .sort((a, b) =>
      this.translateService.instant('CountryNames.' + a.name).localeCompare(this.translateService.instant('CountryNames.' + b.name)),
    );

  constructor(
    @Inject(ActivatedRoute) private route: TypedRoute<UserResolve>,
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private coreDataService: CoreDataService,
  ) {}

  ngOnInit() {
    this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
      this.userService.setUser(data.user);
    });
    this.initializeUserData();
    this.setupValueChangeHandlers();
  }

  contactDetailsForm = this.initContactDetailsForm();

  handleSubmit() {
    let updatedUser: User = {
      email: this.user!.email,
      first_name: this.contactDetailsForm.value.first_name ?? null,
      last_name: this.contactDetailsForm.value.last_name ?? null,
      business: this.contactDetailsForm.value.business ?? false,
      company_name: this.contactDetailsForm.value.company_name ?? null,
      vat_id: this.contactDetailsForm.value.vat_id ?? null,
      street: this.contactDetailsForm.value.street ?? null,
      street_number: this.contactDetailsForm.value.street_number ?? null,
      zip_code: this.contactDetailsForm.value.zip_code ?? null,
      city: this.contactDetailsForm.value.city ?? null,
      country: this.contactDetailsForm.value.country ?? 'AT',
    };
    if (this.contactDetailsForm.valid) {
      this.userService.updateUser(updatedUser).subscribe({
        next: () => {
          this.coreDataService.setCustomerAddressCompletion(true);
          this.toastService.add({ type: 'success', message: this.translateService.instant('UserData.UpdatedSuccessfully') });
        },
        error: (error) => {
          if (error.status === 400 && error.error?.detail) {
            this.setFormErrors(error.error.detail);
            this.toastService.add({ type: 'error', message: this.translateService.instant('UserData.UpdateFailedBadRequest') });
          } else {
            this.toastService.add({ type: 'error', message: this.translateService.instant('UserData.UpdateFailed') });
          }
        },
      });
    }
  }

  private setFormErrors(errors: { [key: string]: string[] }) {
    for (const key in errors) {
      if (errors.hasOwnProperty(key) && this.contactDetailsForm.controls[key as keyof typeof this.contactDetailsForm.controls]) {
        const control = this.contactDetailsForm.controls[key as keyof typeof this.contactDetailsForm.controls];
        control.setErrors({ serverError: errors[key].join(' ') });
      }
    }
  }

  // Helper functions
  private initContactDetailsForm() {
    return this._formBuilder.nonNullable.group({
      email: [fbString, Validators.required],
      first_name: [fbString, Validators.required],
      last_name: [fbString, Validators.required],
      street: [fbString, Validators.required],
      street_number: [fbString, Validators.required],
      zip_code: [fbString, Validators.required],
      city: [fbString, Validators.required],
      country: [<ISO2CountryCode>'AT', Validators.required],
      business: [fbBoolean],
      company_name: [fbOptionalString],
      vat_id: [fbOptionalString],
    });
  }

  private initializeUserData() {
    this.userService.userData.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (user) => {
        this.user = user;
        this.populateForms(user);
      },
      error: () => {},
    });
    this.languageService.languageChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: () => {
        this.selectableCountries = licensePlateCountries
          .slice()
          .sort((a, b) =>
            this.translateService.instant('CountryNames.' + a.name).localeCompare(this.translateService.instant('CountryNames.' + b.name)),
          );
      },
    });
  }

  private populateForms(user: User | null) {
    if (!user) return;
    this.populateContactDetailsForm(user);
  }

  private populateContactDetailsForm(user: User) {
    this.contactDetailsForm.controls.email.patchValue(user.email ?? fbString);
    this.contactDetailsForm.controls.first_name.patchValue(user?.first_name ?? fbString);
    this.contactDetailsForm.controls.last_name.patchValue(user?.last_name ?? fbString);
    this.contactDetailsForm.controls.business.patchValue(user?.business ?? fbBoolean);
    this.contactDetailsForm.controls.company_name.patchValue(user?.company_name ?? fbOptionalString);
    this.contactDetailsForm.controls.vat_id.patchValue(user?.vat_id ?? fbOptionalString);
    this.contactDetailsForm.controls.street.patchValue(user?.street ?? fbString);
    this.contactDetailsForm.controls.street_number.patchValue(user?.street_number ?? fbString);
    this.contactDetailsForm.controls.zip_code.patchValue(user?.zip_code ?? fbString);
    this.contactDetailsForm.controls.city.patchValue(user?.city ?? fbString);
    this.contactDetailsForm.controls.country.patchValue(<ISO2CountryCode>user?.country ?? 'AT');
    this.contactDetailsForm.controls.email.disable();

    this.contactDetailsForm.updateValueAndValidity();
  }

  private setupValueChangeHandlers() {
    this.contactDetailsForm.controls.business.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isBusiness) => {
      const businessControls = [
        this.contactDetailsForm.controls.company_name,
        // this.contactDetailsForm.controls.address,
        // this.contactDetailsForm.controls.zip_code,
        // this.contactDetailsForm.controls.city,
        // this.contactDetailsForm.controls.country,
      ];
      if (isBusiness) {
        for (let control of businessControls) {
          control.setValidators([Validators.required]);
          control.updateValueAndValidity();
          control.markAsUntouched();
        }
      } else {
        for (let control of businessControls) {
          control.clearValidators();
          control.updateValueAndValidity();
        }
      }
    });
  }
}
