<div class="flex items-baseline headline-x-small mb-2">
  <i class="fa-kit fa-contract"></i>
  <div class="flex flex-wrap pl-2">
    <span [innerText]="'Dashboard.ParkingWithContract' | translate"></span>
  </div>
</div>

<div class="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-5 mt-2" [dynamicContractsGrid]="contracts">
  @for (contract of contracts; track contract.id) {
    <div class="card flex flex-col">
      <div class="card-body inline-flex w-full">
        <div class="text-success-dark mr-3">
          <i class="text-4xl fa-kit fa-parking mt-1"></i>
        </div>
        <div class="flex items-center w-full">
          <div class="w-100">
            @if (contract.garage) {
              <div class="body-large">{{ contract.garage.name }}</div>
              <div class="body-medium text-neutral-50" *ngIf="!isRpcFallbackGarage(contract)">
                {{ formatAddress(contract.garage.street, contract.garage.number, contract.garage.zip_code, contract.garage.city) }}
              </div>
            } @else {
              <div class="body-large">{{ contract.name }}</div>
            }
          </div>
          @if (contractQrCodeEnabled) {
            <div class="ml-auto flex flex-col text-center items-center">
              <i class="text-2xl cursor-pointer fa-kit fa-qr-code" (click)="openQrDialog(contract.id)"></i>
              <div class="inline-flex items-center">
                <p class="body-medium text-neutral-50" [innerText]="'Dashboard.Contracts.AccessCode' | translate"></p>
              </div>
            </div>
          }
        </div>
      </div>
      <div class="card-body inline-flex items-center justify-between w-full">
        <div>
          <div class="body-medium text-neutral-50" [innerText]="'Dashboard.Contracts.Contract' | translate"></div>
          <div class="body-medium text-neutral-10">{{ contract.name }}</div>
        </div>
        <div class="inline-flex">
          <div class="ml-2 body-large">
            <i class="fa-kit fa-parking-space mr-1.5"></i>
            <span [innerText]="contract.group_limit"></span>
          </div>
        </div>
      </div>
      <div class="card-body w-full flex-grow">
        <div class="body-medium text-neutral-50" [innerText]="'Dashboard.Contracts.AllowedVehicles' | translate"></div>
        @if (contract.license_plates.length > 0) {
          <div class="flex flex-wrap justify-between items-center gap-2 mt-2">
            @for (licensePlate of contract.license_plates; track $index) {
              <arivo-license-plate [country]="licensePlate.license_plate_country" [licensePlate]="licensePlate.license_plate" />
            }
          </div>
        } @else {
          <div class="body-medium mt-1" [innerText]="'Dashboard.Contracts.NoVehiclesAllowed' | translate"></div>
        }
      </div>
      <div class="card-body items-center">
        <div class="inline-flex justify-between w-full">
          <div class="body-medium text-neutral-50" [innerText]="'Dashboard.Contracts.MonthlyPrice' | translate"></div>
          <div class="body-medium text-neutral-10">{{ contract.price.amount | localizedCurrency: contract.price.currency }}</div>
        </div>
        <div class="inline-flex justify-between w-full">
          @if (contract.end) {
            <div class="body-medium text-neutral-50" [innerText]="'Dashboard.Contracts.ContractDuration' | translate"></div>
            <div class="body-medium text-neutral-10">{{ contract.start | localizedDate }} - {{ contract.end | localizedDate }}</div>
          } @else {
            @if (dateIsInFuture(contract.start)) {
              <div class="body-medium text-neutral-50" [innerText]="'Dashboard.Contracts.ContractDurationFrom' | translate"></div>
            } @else {
              <div class="body-medium text-neutral-50" [innerText]="'Dashboard.Contracts.ContractDurationSince' | translate"></div>
            }
            <div class="body-medium text-neutral-10">
              {{ contract.start | localizedDate }}
            </div>
          }
        </div>
      </div>
      <div class="card-footer">
        <button
          class="button-primary-text p-0"
          [innerText]="'Dashboard.Contracts.AdjustContract' | translate"
          routerLink="/contracts/edit/{{ contract.id }}"
        ></button>
      </div>
    </div>
  }
  @if (creatingContractEnabled) {
    <div class="panel" [routerLink]="hasPaymentMethod ? '/contracts/create' : null" [class.disabled]="!hasPaymentMethod">
      <i class="text-3xl fa-kit fa-contract-new"></i>
      <p [innerText]="'Dashboard.Contracts.AddContract' | translate"></p>
      <p *ngIf="!hasPaymentMethod" class="text-center" [innerText]="'Dashboard.Contracts.AddPaymentMethodFirst' | translate"></p>
    </div>
  } @else {
    <div class="panel disabled">
      <i class="text-3xl fa-kit fa-contract-new"></i>
      <p [innerText]="'Dashboard.Contracts.AddContract' | translate"></p>
      <div class="body-medium text-center">
        <span
          class="text-neutral-70"
          [innerHtml]="'Contracts.ContractsCanOnlyBeConcludedViaCustomerManagementDepartment' | translate"
        ></span>
      </div>
    </div>
  }
</div>

<arivo-dialog #qrDialog>
  <div class="card">
    <div class="card-header">
      <div class="headline-small" [innerText]="'Dashboard.Contracts.AccessCode' | translate"></div>
    </div>
    <div class="card-body flex items-center justify-center">
      <p *ngIf="qrCode" class="body-medium w-full">
        <img [src]="qrCode" alt="QR-Code" class="w-full h-full" />
      </p>
      <p *ngIf="!qrCode" class="body-medium">
        <mat-spinner></mat-spinner>
      </p>
    </div>
    <div class="card-body">
      <div class="body-medium" [innerText]="'Tooltip.AccessCode' | translate"></div>
    </div>
  </div>
</arivo-dialog>
