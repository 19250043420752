import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type Toast = {
  title?: string;
  message: string;
  duration?: number;
  type: 'info' | 'success' | 'warning' | 'error';
  timeout?: ReturnType<typeof setTimeout>;
};

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toasts: Toast[] = [];
  toastsSubject = new Subject<Toast[]>();

  constructor() {}

  add(toast: Toast, duration = 5000) {
    if (!toast.duration) {
      toast.duration = duration;
    }

    toast.timeout = setTimeout(() => this.remove(this.toasts.indexOf(toast)), duration);
    this.toasts.push(toast);
    this.toastsSubject.next(this.toasts);
  }

  showSuccess(message: string) {
    this.add({ message, type: 'success' });
  }

  showError(message: string) {
    this.add({ message, type: 'error' });
  }

  remove(index: number) {
    if (this.toasts[index]?.timeout) {
      clearTimeout(this.toasts[index].timeout);
      this.toasts.splice(index, 1);
      this.toastsSubject.next(this.toasts);
    }
  }
}
