import { parseISO } from 'date-fns';
import { MonoTypeOperatorFunction, tap } from 'rxjs';
import { LoadingService } from '../../core/services/loading.service';
import { EventEmitter } from '@angular/core';

/**
 * Tries best to parse the given date. It supports
 * - numbers -> unix timestamp (UTC)
 * - strings -> ISO 8601 with timezone support (Z or +0100)
 * - Date objects
 * - undefined/null/"" -> undefined
 *
 * NOTE: If not timezone information is given in string dates, it is assumed to be UTC.
 */
export function parseDate(date: string | number | Date): Date;
export function parseDate(date: string | number | Date | null | undefined): Date | undefined;
export function parseDate(date: any): Date | undefined {
  if (date === undefined || date === null) {
    return undefined;
  }

  // Check if the date is a string and matches the format YYYY-MM-DD
  if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
    // Append "T00:00:00" to make it a full ISO 8601 date string
    date += 'T00:00:00';
  }

  return parseISO(date);
}

export function showSpinner<T>(): MonoTypeOperatorFunction<T> {
  return (source) => {
    return source.pipe(
      tap({
        subscribe: () => {
          LoadingService._instance.setLoading(true);
        },
        finalize: () => {
          LoadingService._instance.setLoading(false);
        },
      }),
    );
  };
}

/**
 * Formats the given date to a string in the format "YYYY-MM-DD".
 * This reflects the time the user sees on screen.
 * If it's 1 am on the 1st of January 1900 on the user's screen, it will return 1900-01-01. Regardless where the user is.
 * @param date
 */
export function formatDateWithTimezone(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

/**
 * Sorts the given array in place by the given key.
 * @param array The array to sort
 * @param keySelector The key selector function (identity mapping by default)
 * @param order The order to sort in (ascending by default)
 * @returns The sorted array
 */
export function sortArray<T>(array: T[], keySelector: (x: T) => any = (x) => x, order: 'asc' | 'desc' = 'asc'): T[] {
  if (order === 'asc') {
    return array.sort((a, b) => {
      if (typeof keySelector(a) === 'string') {
        return keySelector(a).localeCompare(keySelector(b));
      } else if (keySelector(a) > keySelector(b)) {
        return 1;
      } else if (keySelector(a) < keySelector(b)) {
        return -1;
      } else {
        return 0;
      }
    });
  } else {
    return array.sort((a, b) => {
      if (typeof keySelector(a) === 'string') {
        return keySelector(b).localeCompare(keySelector(a));
      } else if (keySelector(b) > keySelector(a)) {
        return 1;
      } else if (keySelector(b) < keySelector(a)) {
        return -1;
      } else {
        return 0;
      }
    });
  }
}

export function formatAddress(street: string | null, number: string | null, zipCode: string | null, city: string | null): string {
  const streetPart = [street, number].filter((part) => part && part.trim() !== '').join(' ');
  const cityPart = [zipCode, city].filter((part) => part && part.trim() !== '').join(' ');
  return [streetPart, cityPart].filter((part) => part && part.trim() !== '').join(', ');
}

let activeCalls = 0;
export function setLoading<T>(cls: { loading: boolean; loadingChange?: EventEmitter<boolean> }): MonoTypeOperatorFunction<T> {
  return (source) => {
    return source.pipe(
      tap({
        subscribe: () => {
          activeCalls++;
          if (activeCalls === 1) {
            cls.loading = true;
            cls.loadingChange?.emit(true);
          }
        },
        finalize: () => {
          activeCalls--;
          if (activeCalls === 0) {
            cls.loading = false;
            cls.loadingChange?.emit(false);
          }
        },
      }),
    );
  };
}
