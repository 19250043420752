<div class="card-responsive card-horizontal mx-auto">
  <div class="card-body">
    <div class="text-left">
      <a
        routerLink="/login"
        class="inline-flex text-decoration-none items-center body-medium text-interaction-dark mb-4"
        (click)="cancel()"
      >
        <i class="fa-light fa-chevron-left"></i>
        <span class="text-underlined pl-2" [innerText]="'Common.Back' | translate"></span>
      </a>
    </div>

    <div class="flex-responsive items-center gap-4 mb-4">
      <i class="text-3xl text-decoration-dark fa-kit fa-email"></i>
      <div class="body-large text-left text-decoration-dark" [innerText]="'LogIn.PleaseCheckYourEmailInbox' | translate"></div>
    </div>
    <div class="well">
      <p class="body-medium mb-2" [innerHtml]="'LogIn.WeHaveSentAnEmail' | translate: { email: email }"></p>
      <p class="body-medium" [innerText]="'LogIn.FollowTheLinkToLogIn' | translate"></p>
    </div>
    <div class="mt-4">
      <p class="body-medium">
        <span [innerText]="'LogIn.HaveNotReceivedAnEmail' | translate"></span>
        <span>&nbsp;</span>
        <a
          class="cursor-pointer"
          [title]="'LogIn.SendAgain' | translate"
          [innerText]="'LogIn.SendAgain' | translate"
          (click)="sendLogInEmail()"
        ></a>
      </p>
    </div>

    <div class="hidden">
      <div class="separator my-4" [innerText]="'Common.Or' | translate"></div>
      <button class="button-primary-outlined button-small w-full gap-2 my-3">
        <i class="fa-brands fa-google"></i>
        <span [innerText]="'LogIn.SignInWith' | translate: { provider: 'Google' }"></span>
      </button>
      <button class="button-primary-outlined button-small w-full gap-2 mt-3">
        <i class="fa-brands fa-apple"></i>
        <span [innerText]="'LogIn.SignInWith' | translate: { provider: 'Apple' }"></span>
      </button>
    </div>
  </div>
  <div class="card-body text-left">
    <app-customer-service-info />
  </div>
</div>
